.hp-anon {
	margin-top: -5px;
	padding-bottom: 0;
	width: 100%;
	max-width: 100%;
	min-height: 250px;
	background: #d6e5e8;
	background: -webkit-linear-gradient(288deg, #d6e5e8, #dcd9e4);
	background: -moz-linear-gradient(288deg, #d6e5e8, #dcd9e4);
	background: linear-gradient(288deg, #d6e5e8, #dcd9e4);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcd9e4', endColorstr='#d6e5e8',GradientType=1 );
	z-index: 1;
	display: none;

	@include breakpoint(3) {
		min-height: 297px;
	}

	@include breakpoint(2) {
		margin-top: 0;
		min-height: 273px;
	}

	@include breakpoint(1) {
		margin-bottom: 10px;
		min-height: 206px;
	}

	.logged-out & {
		display: block;
	}

	.anon-graphic {
		background: url("/pi/global/mktg/anon-user-graphic.png") right bottom no-repeat;
		background-size: 705px auto;

		@include breakpoint(3) {
			background: url("/pi/global/mktg/anon-user-graphic-landscape.png") right bottom no-repeat;
			background-size: 504px auto;
		}

		@include breakpoint(2) {
			background: url("/pi/global/mktg/anon-user-graphic-tablet.png") right bottom no-repeat;
			background-size: 419px auto;
		}

		@include breakpoint(1) {
			background: url("/pi/global/mktg/anon-user-graphic-mobile.png") right bottom no-repeat;
			background-size: 175px auto;
		}
	}

	.section-container {
		margin: 0 auto;
		padding: 0;
		max-width: 96%;
		width: 1240px;
		display: block;
	}

	.section-title {
		margin: 0 0 16px;
		padding: 24px 0 0;
		font-family: $font-pn-light;
		font-size: 1.375rem;
		color: #000;

		@include breakpoint(3) {
			margin: 0 0 14px;
			padding: 20px 0 0;
		}

		@include breakpoint(2) {
			margin: 0 0 14px 10px;
			padding: 18px 0 0;
			font-size: 1.125rem;
		}

		@include breakpoint(1) {
			margin: 0 0 5px 10px;
			padding: 12px 0 0;
			font-size: 0.8125rem;
		}

		.mscp {
			font-size: 0;
			width: 126px;
			height: 29px;
			background: transparent url("/pi/logos/mscp-logo.png") no-repeat;
			background-size: 126px auto;
			display: inline-block;

			@include breakpoint(2) {
				width: 104px;
				height: 24px;
				background-size: 104px auto;
			}

			@include breakpoint(1) {
				width: 77px;
				height: 18px;
				background-size: 77px auto;
			}
		}

		.title-text {
			padding: 0 0 1px 1px;
			vertical-align: bottom;
			display: inline-block;

			@include breakpoint(2) {
				padding: 6px 0 0 1px;
				vertical-align: top;
			}

			@include breakpoint(1) {
				padding: 1px 0 0 1px;
			}
		}
	}

	p {
		padding: 0;
		font-family: $font-pn;
		color: $title-gray;

		&.description {
			margin: 0 0 20px;
			font-size: 2rem;
			line-height: 2.125rem;
			width: 615px;

			@include breakpoint(3) {
				margin: 0 0 18px;
				width: 503px;
			}

			@include breakpoint(2) {
				margin: 0 0 22px 10px;
				font-size: 1.625rem;
				line-height: 1.75rem;
				width: 324px;
			}

			@include breakpoint(1) {
				margin: 0 0 14px 10px;
				font-size: 0.9375rem;
				line-height: 1.063rem;
				width: 220px;
			}
		}

		&.login-line {
			margin: 0;
			padding: 0 0 22px;
			font-size: 1rem;
			line-height: 1.375rem;

			@include breakpoint(2) {
				padding: 0 0 22px 10px;
			}

			@include breakpoint(1) {
				padding: 0 0 20px 10px;
				font-size: 0.9375rem;
			}
		}
	}

	a {

		&.sign-up {
			margin: 0 0 21px;
			padding: 12px 22px;
			background-color: transparent;
			background: $mscp-blue;
			font-family: $font-pn-semibold;
			font-size: 1.25rem;
			color: $white;
			border-radius: 3px;
			display: inline-block;

			&:focus {
				outline: 0;
			}

			&:hover {
				background: $hover-blue;
				@include hover-ease;
			}

			@include breakpoint(3) {
				margin: 0 0 16px;
			}

			@include breakpoint(2) {
				margin: 0 0 20px 10px;
				padding: 10px 30px;
				font-size: 1rem;
			}

			@include breakpoint(1) {
				margin: 0 0 16px 10px;
				padding: 10px 16px;
			}
		}

		&.login-link {
			color: $mscp-blue;
			
			&:hover {
				color: $hover-blue;
				@include hover-ease;
			}
		}
	}
}

// z-index
$z-index_layer: 6000000;

// colors
$mscp-blue: #064aa7;
$hover-blue: #0f428a;
$dark-gray: #2a2a2a;
$light-gray: #d8d8d8;
$lighter-gray: #f1f0ee;
$bg-gray: #f7f7f7;
$byline-gray: #767674;
$medium-gray: #979797;
$dark-purple: #2f2148;
$teal: #3894a2;
$light-blue: #77a9ed;
$bg-blue: #f0f9ff;
$white: #fff;
$light-black: #222;
$red: #d0021b;
$green: #839469;
$title-gray: #333132;
$header-gray: #e8e6e6;
$intro-gray: #484a4a;
$link-purple: #5757a6;
$gray: #888;
$title-blue: #0b3770;
$link-blue: #15a1ff;
$link-hover-blue: #065991;

// Digital Hub
$neutral-0:  #020202;
$neutral-10: #2B2E31;
$neutral-20: #51585D;
$neutral-80: #E7EBED;

// fonts (ordered by weight)
$font-pn-thin:          'proxima_nova_ththin', sans-serif;
$font-pn-thin-ital:     'proxima_nova_ththin', sans-serif;
$font-pn-light:         'proxima_nova_ltlight', sans-serif;
$font-pn-light-ital:    'proxima_nova_ltlight', sans-serif;
$font-pn:               'proxima_nova_rgregular', sans-serif;
$font-pn-ital:          'proxima_nova_rgregular', sans-serif;
$font-pn-semibold:      'proxima_nova_ltsemibold', sans-serif;
$font-pn-semibold-ital: 'proxima_nova_ltsemibold', sans-serif;
$font-pn-bold:          'proxima_nova_rgbold', sans-serif;
$font-pn-bold-ital:     'proxima_nova_rgbold', sans-serif;

$font-rb: 'Roboto Condensed',sans-serif;

$font-serif: 'EB Garamond', serif;
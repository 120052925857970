.hp_editors {
	border-top: 6px solid $header-gray;
	position: relative;

	.section-container {
		padding: 40px 0;
		flex-flow: row wrap;
	}

	.editor-image {
		margin: 0 19px 0 0;
		display: inline-block;
		
		img {
			border-radius: 50%;
			width: 60px;
			height: 60px;
		}
	}

	.editor-title {
		margin: 8px 0 0;
		display: inline-block;
		vertical-align: top;

		.title {
			padding: 0 18px 0 0;
			font-family: $font-pn;
			font-size: 1.625rem;
			line-height: 1.188rem;
			color: $mscp-blue;
			display: block;

			&:hover {
				color: $hover-blue;
				@include hover-ease;
			}
		}

		.role {
			margin-top: 8px;
			color: $title-gray;
			display: block;
		}
	}

	.teaser {
		font-size: 0.875rem;
		line-height: 1rem;
		display: inline-block;
	}

	a {
		color: $mscp-blue;
		font-size: 0.75rem;

		&:hover {
			color: $hover-blue;
		}
	}

  	.editors-item {
  		margin: 0 0 0 15px;
  		padding: 0 0 0 15px;
  		border-left: 1px solid $light-gray;
  		flex: 1;

		&:first-child {
			margin: 0;
			padding: 0;
			border: none;
			flex-basis: 20%;

			@include breakpoint(2) {
				margin: 0 0 40px 0;
				display: flex;
				flex: 1 0 100%;

				.editor-image {
					flex: 0 1 100px;
				}

				.editor-title {
					flex: 0 1 400px;

					a {
						display: inline-block;
					}
				}

				p {
					display: flex;
					flex: 1 1 80%;
				}

				.teaser {
					padding: 0;
				}
			}

			@include breakpoint(1) {
				margin: 0 0 30px 0;
				display: block;

				.teaser {
					padding: 8px 0;
					border-bottom: 1px solid $header-gray;
				}
			}
		}

		&:nth-child(2) {
			flex-basis: 15%;

			ul {
				-webkit-column-count: 2;
    			-moz-column-count: 2;
    			column-count: 2;

    			@include breakpoint(1) {
    				-webkit-column-count: 1;
    				-moz-column-count: 1;
    				column-count: 1;
    			}
			}

			@include breakpoint(2) {
				margin: 0;
				padding: 0;
				border: none;
			}

			@include breakpoint(1) {
				margin: 0 0 30px 0;
			}
		}

		&:last-child {
			margin: 0;
			padding: 0;
			border: none;
			flex: 0;
			display: block;
			position: absolute;
			bottom: 35px;
			right: 0;

			@include breakpoint(2) {
				bottom: 20px;
			}

			@include breakpoint(1) {
				position: static;
				display: inline-block;
			}

			.morelink {
				padding: 0 12px 0 0;
				font-family: $font-rb;
				font-size: 0.8125rem;
				font-weight: bold;
				text-transform: uppercase;
				background: url("/pi/global/icons/icon-arrow-right-blue.svg") right center no-repeat;
				background-size: 7px auto;
			}
		}

		@include breakpoint(1) {
			margin: 0 0 30px 0;
			padding: 0;
			border: none;
		}

  		span {
  			
  			p {
  				font-family: $font-rb;
  				font-size: 0.8125rem;
  				font-weight: bold;
  				color: $title-gray;
  				text-transform: uppercase;
  			}
  		}
  	}

  	ul {
    	list-style: none;

    	li {
    		margin: 0;
    		padding: 0 0 8px 0;
    		line-height: 1;
    		-webkit-column-break-inside: avoid;
    		page-break-inside: avoid;
    		break-inside: avoid-column;
    	}
  	}
}
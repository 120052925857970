.hp_mrktcallouts {
  background-image: linear-gradient(300deg, #ebf3f5, #dbe5f0);
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.09);
  width: 100%;
  max-width: none;
  padding-bottom: 30px;
  position: relative;

  .section-title {
    padding: 24px 0 12px;
    max-width: 96%;

    @include breakpoint(1) {
      max-width: 90%;
    }
  }

  .subtitle {
    padding: 22px 30px 8px 30px;
    text-transform: uppercase;
    color: $byline-gray;
    font-family: $font-rb;
    text-align: center;
    display: block;

    @include breakpoint(3) {
      padding-top: 18px;
    }

    @include breakpoint(1) {
      padding: 15px 15px 8px 15px;
      font-size: 14px;
    }
  }

  .title {
    padding: 0 30px;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    display: block;

    @include breakpoint(4) {
      height: 100px;
    }

    @include breakpoint(1) {
      padding: 0 15px 24px 15px;
      min-height: 100px;
    }
  }


  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    // use in object-fit-image polyfill for IE
    font-family: "object-fit: cover; object-position: center;";

    @include breakpoint(1) {
      height: auto;
      min-height: 183px;
      object-position: left;
    }
  }

  .unwrapAround.carousel-container {
    width: 1240px;
    max-width: 96%;
    margin: 0 auto;

    &:not(#callouts){
      @include breakpoint(3) {
          width: 80%;
      }
    }

    .flickity-viewport {
      overflow: visible;
    }
  }

  .wrapAround .carousel-item {
    opacity: 0.5;
    width: 28%;

    @include breakpoint(4) {
      min-width: 400px;
    }

    @include breakpoint(2) {
      width: 40%;
    }

    @include breakpoint(1) {
      width: 70%;
    }
  }

  .unwrapAround .carousel-item {
    opacity: 0;
    width: 31%;

    @include breakpoint(2) {
      width: 48%;
    }

    @include breakpoint(1) {
      width: 95%;
    }
  }

  .carousel-item {
    margin: 10px;
    min-height: 360px;
    position: relative;
    background-color: #fafafa;
    box-shadow: 0 0 10px 0 rgba(98, 120, 141, 0.2);
    box-sizing: border-box;

    & > div {
      position: relative;
      height: 100%;

      & > a {
        position: relative;
        height: 100%;
        display: block;

		&:hover {
			text-decoration: none;

			.title {
				color: $hover-blue;
				@include hover-ease;
			}
		}
      }
    }

    &.is-selected {
      opacity: 1;
      transition: transform 0.3s;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 10px 0 rgba(98, 120, 141, 0.5);
      }
    }

    @include breakpoint(2) {
      height: 390px;
    }

    @include breakpoint(1) {
      height: 335px;
      min-height: 325px;
    }
  }

  .flickity-prev-next-button {
    width: 60px;
    height: 60px;
    background-color: $mscp-blue;
    color: $white;
    z-index: 2;

    .arrow {
      fill: $white;
    }

    &.previous {
      left: 0;
    }

    &.next {
      right: 0;
    }

    &:hover {
      background: $hover-blue;
    }

    @include breakpoint(1) {
      display: none;
    }
  }
}

.hp_nativecallouts {
  display: none;
}

li.nativecallouts-item {
	border: solid 1px #abccd9 !important;
	padding: 10px 12px !important;
	margin: 0 -12px;
	position: relative;
	overflow: hidden;

	.title a {
		color: $title-gray;

		&:visited {
			color: $title-gray;		
		}

		&:hover {
			color: $mscp-blue;
			@include hover-ease;
		}
	}

  @include breakpoint(2) {
    &:not(:first-child):not(:last-child):nth-child(3) {
      margin: 0 0 0 -12px;
    }
  }

  @include breakpoint(1) {
    padding: 10px 10px;
    margin: 0 -10px 10px -10px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #fff;
    z-index: 2;
    left: 0;
    top: -2px;
  }

  &::after {
    bottom: -2px;
  }
}

#callouts{

}
.page-body.resp-container {
	flex-direction: column;
	position: relative;
	width: auto;
	max-width: none;
	overflow: hidden;
}

section, h1, h2, h3 {
	max-width: 96%;
	width: 1240px;
	margin: 0 auto;

	@include breakpoint(3) {
		width: 96%;
	}

	@include breakpoint(1) {
		width: 90%;
	}
}

h1 {
	margin-bottom: 8px;
	color: $title-gray;

	@include breakpoint(2) {
		font-size: 2.625rem;
	}

	@include breakpoint(1) {
		margin: 13px auto 0;
		font-size: 1.438rem;
		text-align: center;
	}
}

.rc {

	h1 {
		padding-bottom: 4px;
		border-bottom: 5px solid $light-gray;

		@include breakpoint(1) {
			border-bottom: none;
			margin-bottom: 1rem;
		}
	}
}

.header-action {
	display: none;
}

.rc .header-action,
.coe .header-action {
	display: block;
}
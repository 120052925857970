// geo-targeting - currently used to display UK-targeted content (UK landing page & UK featured section on homepages)
#country-content {
	display: none;

	+ .hp_bucket2col {
		border-top: none;

		.section-title {
			display: none;

			.more {
				display: none;
			}
		}
	}

	+ .hp_news {
		border-top: none;
	}

	&.active {
		display: block;

		+ .hp_bucket2col {
			border-top: 2px solid $header-gray;

			.section-title {
				display: block;

				.more {
					display: inline;
				}
			}
		}

		+ .hp_news {
			border-top: 2px solid $header-gray;
		}

		.section-title {
			padding-left: 45px;
			background: url("/pi/global/flags/flag-uk.svg") no-repeat;
			background-size: 34px auto;
			background-position: left 0 bottom 5px;

			@include breakpoint(1) {
				padding-left: 35px;
				line-height: 1.25rem;
				background-size: 26px auto;
			}
		}
	}
}

// UK landing page header
.page-body.resp-container.hp {
	&.ct-gb h1 {
		width: 1175px;
		padding-left: 65px;
		background: url("/pi/global/flags/flag-uk.svg") no-repeat;
		background-size: 54px auto;
		background-position: left center;
		
		@include breakpoint(3) {
			max-width: 90%;
		}

		@include breakpoint(2) {
			margin-left: 20px;
		}

		@include breakpoint(1) {
			margin-left: auto;
			background-size: 34px auto;
			padding-left: 45px;
			text-align: left;
			width: auto;
		}
	}
}
.hp {

	&.Ophthalmology {

		.hp_resource-center, 
		.hp-anon,
		.hp_alerts {
			&+section:not(.hp-anon):not(.hp_alerts) {
				display: none;			
			}
		}

		&.optometrist {
			.hp_resource-center, 
			.hp-anon,
			.hp_alerts {
				&+section:not(.hp-anon):not(.hp_alerts) {
					display: block;			
				}
			}
		}
	}
}
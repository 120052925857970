.hp_cardlayout{
    width:100%;
    max-width:100%;
    padding-bottom:25px;
    @include linear-bg(left,#DBE5F0, #EBF3F5);

    .section-title{
        @include breakpoint(3){
            width:96%;
        }

        @include breakpoint(1){
            width:90%;
        }
    }


    .section-container ul {

        @include breakpoint(1) {
			width: 100%;

            &:after {
                content: 'flickity';
                display: none;
            }

            .flickity-prev-next-button {
                display: none;
            }

            .flickity-page-dots {
                display: block !important;
            }
        }
    }


    ul {

		display: flex;
		list-style: outside none none;
		margin: 0 auto;
		max-width: 96%;
		width: 1240px;

		@include breakpoint(3) {
			overflow-x: hidden;
		}

		@include breakpoint(2) {
			overflow-x: scroll;
		}

	    @include breakpoint(1) {
            max-width: 100%;
            overflow-x: hidden;
            display: block;
    	}

    	li {
    		-moz-box-flex: 1;
    		background-color: #fafafa;
    		box-shadow: 0 0 10px 0 rgba(98, 120, 141, 0.2);
    		flex: 1 1 0;
    		margin: 5px;
    		padding-bottom: 20px;
    		position:relative;
    		text-align: center;
    		top: 0;
    		transition: transform 0.5s ease 0s;

			&:hover {
				transform: translateY(-5px);
				box-shadow: 0 0 10px 0 rgba(98, 120, 141, 0.5);
			}

    		img{
                // img height and width comes from javascript utility hpCardLayoutImgCrop
    			object-fit: cover;
                object-position: center;
                // use in object-fit-image polyfill for IE
                font-family: "object-fit: cover; object-position: center;";
    		}

    		.title{
    			margin: 10px 20px 20px 20px;
    			text-align: center;
    		}

			a:hover {
				text-decoration: none;

				.title {
					color: $hover-blue;
					@include hover-ease;
				}
			}

            .byline{
                text-align:center;
                display: none;
            }

    		@include breakpoint(3) {
				margin:5px;
                width: 10em;                
			}

			@include breakpoint(2) {
				width: auto;
				flex: 0 0 16em;
			}
            @include breakpoint(1) {
                min-height: 323px;
                width: 70%;
                min-width: 10em;

                 &.dot {
                     min-height: 0;
                     min-width: 0;
                }
            }

            &.card-small-thumb{
                padding:15px;
                .tag{
                    text-align: left;
                }
                .title{
                    padding:0;
                    margin:0 0 7px 0;
                    text-align:left;
                }
                .byline{
                    text-align:left;
                    color:#999999;
                    padding:0;
                    margin:0 0 10px 0;
                    display: none;
                }
                img{
                    float:right;
                    @include smallImg;
                }
                .teaser{
                    color: $light-black;
                    font-size: 16px;
                    line-height: 1.25;
                     @include breakpoint(1) {
                        display:block   
                    }
                }
            }

    	}
    }

    .flickity-slider li:hover {
      transform: translateY(-5px);
    }

    @include breakpoint(1) {
    	.more {
    		padding-top: 10px;
    		
    		a {
        		margin-left: 13px;
        	}
        }
    }

}

.rc{
    .hp_cardlayout{
        img{
            width:100%;
            height:202px;
        }

    }
}

// hide specialty on OUS (not translated)
.mscp-global .hp_cardlayout .specialty {
	display: none;
}
fieldset {
    border: none;
    padding: 0;
    margin: 0;
    padding-bottom: 2rem;
}

qna-form-id {
    display: none;
}

.qna-question legend{
    max-width:100%;
    width:100%;
}

.qna-question input[type=radio], 
.qna-question input[type=checkbox] {
    height: 0;
    width: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.qna-question label {
    height: auto; 
    margin-bottom: 0.2em;
    cursor: hand;
    cursor: pointer;
    position: relative;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    overflow: hidden;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #edf0f5;
    border-bottom: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid transparent;  
}

.answered label {
    cursor: default;
}

.qna-question span:last-child {
    float: right;
}

.qna-render {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #eff3f6;
    clear:both;
    
    h2 {
        margin: 0 0 16px;
        padding: 0 0 3px;
        font-family: $font-pn-light;
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 1.17;
        color: $dark-purple;
        border-bottom: 2px solid $teal;
    }
    
    p {
        padding: 0 0 10px 0;
    }
    
    @media screen and (max-width: 768px) {
        padding-right: 0;
        padding-left: 0;
        
        label {
            width: 98%;
        }
    }
}

.qna-question img {
    max-width: 100%;
}

.qna-question .answer-content {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    z-index: 100;
    width: 100%;
}

.qna-question:not(.answered) .answer-content ans, 
.qna-question:not(.answered) input + div.answer-bar + div.answer-content ans {
    border: solid 1px rgba(0, 0, 0, 0.7);
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: relative;
    padding: 1px;
    background-color: #fff;
    transition: opacity ease 800ms;
    margin: 12px 10px 0 10px;
}

.qna-question ans span {
    width: 6px;
    height: 6px;
    opacity: 0;
    background: #000;
    border-radius: 50%;
    transition: opacity ease 800ms;
}

.qna-question .answer-content select {
    border: 1px solid #000;
    color: #000;
    background: #fff;
    font-size: 1.25rem;
    padding: 2px 10px;
    width: 100%;
    *width: 100%;
    *background: #fff;
    -webkit-appearance: none;
    background-image: url("//img.medscape.com/pi/cme/icons/dropbox-down.svg");
    background-repeat: no-repeat;
    background-size: 30px auto;
    background-position: 98% center;
    border-radius: 3px;
    font-family: $font-pn-light;
    height: 40px;
    line-height:1.25rem;
    -moz-appearance: none;
    text-indent: -0.01px;
    text-overflow: '';
}

.answer-percent {
    right: 0;
    opacity: 1;
    top: initial; 
    margin: 1.5em 15px 1.5em 5px;
    color: #000;
}

.qna-question input[type=radio] + div.answer-bar + div.answer-content ans {
    border-radius: 50%;
}

.qna-question input[type=checkbox] + div.answer-bar + div.answer-content ans {
    border-radius: 0%;
}

.qna-question input[type=radio] + div.answer-bar + div.answer-content ans, 
.qna-question input[type=checkbox] + div.answer-bar + div.answer-content ans {
    display: block;
    width: 20px;
    height: 20px;
    margin: 17px 10px 0px 10px;
}



.qna-question input[type=checkbox]:checked + div.answer-bar + div.answer-content ans span, 
.qna-question input[type=text] + span.answer-percent, 
.qna-question select + span.answer-percent, 
.qna-question textarea + span.answer-percent {
    display: none;
}

.qna-question input[type=text] {
    border: 0px solid #000;
    height: 44px;
    font-size: 1.063rem;
    padding: 0 10px;
    opacity: 1;
    width: 100%;
}

.qna-question textarea {
    border: 0px solid #000;
    height: 163px;
    font-size: 1.063rem;
    padding: 0 10px;
    opacity: 1;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 163px;
}

.qna-question .answer-item p {
    margin: 0 20px;
    padding: 0;
}

.qna-question .answer-content p {
    margin: 0;
    padding: 10px 40px 10px 0;
    width: 90%;
    font-family: $font-pn-light;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: $dark-gray;
}

.answer-bar {
    height: 400px;
    position: absolute;
    top: 0;
    width: 0%;
    z-index: 100;
    transition: width 0.5s ease
}

.qna-question.answered label:not(.answer-correct) .answer-bar, 
.qna-question.answered label:not(.answer-wrong) .answer-bar {
    background-color: rgba(0, 0, 0, 0.1);
}


/** select **/
.qna-question:not(.answered) label:hover, 
.qna-question:not(.answered) input:checked ~ .answer-content {
    background-color: $teal;
    //border: 1px solid #000;
}

.qna-question:not(.answered) input:checked ~ .answer-content ans {
    background-color: #fff;
}

.qna-question:not(.answered) input:checked ~ .answer-content ans span {
    opacity: 1;
}

.qna-question:not(.answered) label:hover input ~ .answer-content p {
    color: #fff;
    font-family: $font-pn-light;
}

.qna-question:not(.answered) label input:checked ~ .answer-content p {
    color: #fff;
    font-family: $font-pn-semibold;
}

.qna-question:not(.answered) input:checked ~ .answer-bar {
    background-color: $teal;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    border: solid 0px $teal;
}
/** select **/

/** no correct **/
.qna-question.answered label.answer {
    /*background-color: rgb(235,244,245);*/
    border: 1px solid rgb(56, 148, 162);
    box-shadow: 0 0 0 0;
}

.qna-question.answered label.answer .answer-bar {
    background-color: #ebf4f5;
}

.qna-question.answered label ans {
    opacity: 0;
}

.qna-question.answered label span {
    opacity: 1;
}

.qna-question.answered label.answer ans {
    opacity: 0;
}

.qna-question .answered label.answer ans span {
    opacity: 0;
}
/** no correct **/

/** correct **/
.qna-question.answered label.answer-correct {
    /*background-color: rgb(235,244,245);*/
    border: 1px solid rgb(0, 0, 0);
    box-shadow: 0 0 0 0;
}

.qna-question.answered label.answer-correct .answer-bar {
    background-color: #ebf4f5;
}

.qna-question.answered label ans {
    opacity: 0;
}

.qna-question.answered label span {
    opacity: 1;
}

.qna-question{
    &:not(.answered){
        input[type=checkbox]:checked + div.answer-bar + div.answer-content ans {
            background-color: transparent;
            background-image: url("//img.medscape.com/pi/cme/icons/white-checkbox.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            border: 1px solid white;
        }
    }
    &.answered{

        label{
            ans{
                opacity: 1;
                border: solid 0px rgba(0, 0, 0, 0.7);
                width: 20px;
                height: 20px;
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                margin: 17px 10px 0px 10px;
            }
            &.answer-correct{
                ans{
                    background-image: url("//img.medscape.com/pi/cme/icons/correct-answer.svg");
                }
            }
            &.answer-wrong{
                ans{
                    background-image: url("//img.medscape.com/pi/cme/icons/wrong-answer.svg");
                }
            }
            &:not(.answer-wrong){
                input[type=checkbox]:checked + div.answer-bar + div.answer-content ans{
                    background-image: url("//img.medscape.com/pi/cme/icons/correct-answer.svg");
                }
            }
        }
    }
}


.qna-question.answered label.answer-correct ans span {
    opacity: 0;
}
/** correct **/

/** wrong **/
.qna-question.answered label.answer-wrong {
    /*background-color: rgb(235,244,245);*/
    border: 1px solid #d0021b;
    box-shadow: 0 0 0 0;
}
.qna-question.answered label.answer-wrong .answer-bar {
    background-color: #fbe6e8;
}
.qna-question.answered label ans {
    visibility: visible;
    opacity: 1;
}
.qna-question.answered label span:first-child {
    opacity: 0;
}
.qna-question.answered label.answer-wrong ans {
    opacity: 1;
    border: solid 0px rgba(0, 0, 0, 0.7);
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-image: url("//img.medscape.com/pi/cme/icons/wrong-answer.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.qna-question.answered label.answer-wrong ans span {
    opacity: 0;
}
/** wrong **/

#qna-form-actions {
    margin-top: 2em;
}

#qna-form-actions .qna-button {
    display: inline-block;
    text-decoration: none;
}

.qna-button {
    border-radius: 5px;
    background-color: $mscp-blue;
    padding: 10px 29px 10px 29px;
    color: #fff !important;
    font-family: $font-pn;
    font-weight: 600;
    font-size: 1.25rem;
    line-height:normal;
    text-align: center;
    text-transform: uppercase;
    border: 0;    
    text-decoration: none;
    &:hover {
        background-color: $hover-blue;
    }
}

.qna-disabled {
    opacity: 0.5;
    pointer-events: none;
}

#bottom-buttons {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    flex-flow: column;
    margin-top: 5em;
}

#bottom-buttons li {
    list-style: none;
}

#bottom-buttons li button {
    font-size: 1.063rem;
    font-family: $font-pn-bold;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 10px;
    cursor: hand;
    cursor: pointer;
}

.qna-question.answered input.answer-select {
    height: 20px;
    width: 20px;
    opacity: 1;
    position: absolute;
    visibility: visible;
    left: 15px;
}

.qna-question.answered input[type="checkbox"].answer-select {
    visibility: hidden;
}

/* hide the old html */
.qacontainer {
    display: none;
}

/* begin mid content qna styles */
div[data-render="1"] form, 
div[data-render="1"] .qna-qnaresponse {
    display: none;
}

div[data-render="1"].show-questions form { /* show questions */
    display: block;
}

div[data-render="1"].show-questions .qna-qnaresponse { /* when showing questions hide the polling */
    display: none;
}

div[data-render="1"].show-polling .qna-qnaresponse { /* show polling */
    display: block;
}

div[data-render="1"].show-polling form { /* when showing polling hide the questions */
    display: none;
}
/* end mid content qna styles */

.errorMsg {
    font-family: $font-pn-bold;
    color: red;
}

.errorMsg-text {
  display: none;
}

.qna-question.answered label.answer-survey {
    /* background-color: rgb(235,244,245); */
    border: 1px solid rgb(56, 148, 162);
    box-shadow: 0 0 0 0;
}

.qna-question.answered label.answer-survey .answer-bar {
    background-color: #ebf4f5;
}

.qna-question.answered label.answer-survey ans {
    border: solid 0px rgba(0, 0, 0, 0.7);
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.qna-question {

    .questiontext12 {
        margin-bottom: 12px;
        font-family: $font-pn;
    }

    .with-intro {
        font-family: $font-pn-semibold;
    }
}

.no-poll {
    padding: 30px;
}

.no-poll .answerrow {
    margin: 0;
    padding: 0;
    max-width: 100%;
    width: 100%;
}

.qna-question .qnatable input[type=radio] {
    height: 15px !important;
    width: 15px !important;
    opacity: 1 !important;
    position: absolute !important;
    visibility: visible !important;
}
.qna-question .qna-poll-chart{
    font-family: $font-pn-light;
    line-height: 1.3;
    float: right;
}

a.qna-view-button {
    padding: 16px;
    text-decoration: none;

    &:visited,
    &:active {
        text-decoration: none;
        color: $mscp-blue;
    }

    &:hover {
        color: $hover-blue;
    }
}

.qna-total-response {
    padding: 16px;
}

.qna-res-title {
    font-family: $font-pn-bold;
    font-size: 16px;
    line-height: 20px;
}

.qna-poll-data {
    margin-bottom: 19px;
    position: relative;

    svg {
        position: absolute;
        top: 15px;
        left: 265px;
    }

    .qna-poll-select {
        width: 290px;
        height: 40px;
        padding: 8px;
        border-radius: 3px;
        background-color: #fefefe;
        border: solid 1px #e1e1e1;
        font-family: $font-pn-semibold;
        font-size: 16px;
        line-height: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}

.gated-question-login {
    font-size: 20px;
    text-align: center;
    padding-bottom: 16px;
}

.qna-qmatrix,
.qna-qmatrix ul,
.qna-qmatrix li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.qna-qmatrix {
    max-width: 100%;
    flex-flow: column nowrap;

    .qna-qmatrix-header,
    .qna-qmatrix-content {
        flex-flow: row nowrap;
        border-bottom: solid 1px rgba(225, 225, 225, 0.5);
        max-width: initial;
        width: 100%;
    }

    .qna-qmatrix-question {
        flex: 2 1 10px;
        align-items: center;
        font-family: $font-pn-semibold;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: normal;
        padding: 4px 0;
        word-wrap: break-word;

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            width: 230px;
        }
    }

    .qna-qmatrix-answer {
        flex: 1 1 10px;
        font-family: $font-pn-light;
        font-size: 13px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #000;
        align-items: center;
        padding: 10px 10px;
        text-align: center;
        justify-content: center;

        div {
            display: none;
        }

        input[type=radio] + label {
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.7);
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            margin-top: 3px;
            cursor: pointer;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);

            &:hover {
                background: transparent;
            }

            span {
                opacity: 0;
                transition: opacity ease 800ms;
            }
        }

        input[type=radio]:checked + label span {
            border-radius: 50px;
            width: 5px;
            height: 5px;
            opacity: 1;
            background-color: #000;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 1250px) {
    .qna-qmatrix {

        .qna-qmatrix-header {
            display: none;
        }

        .qna-qmatrix-content {
            flex: 1 0 90%;
            flex-flow: row wrap;
            border-bottom: 0px;
            margin-bottom: 32px;
            justify-content: space-between;
        }

        .qna-qmatrix-question {
            flex: 1 0 100%;
            font-size: 20px;
            border-bottom: solid 1px rgba(225, 225, 225, 0.5);
        }

        .qna-qmatrix-answer {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            width: auto;
            align-items: center;

            div {
                display: block;
                margin-bottom: 10px;
                font-size: 13px;
            }

            &:nth-child(2),
            &:nth-last-child(1) {
                width: 70px;
            }

        }
    }
}

@media screen and (max-width: 320px) {
    .qna-qmatrix {

        .qna-qmatrix-answer {
            div {
                font-size: 11px;
            }

            &:nth-child(2),
            &:nth-last-child(1) {
                width: 50px;
            }
        }
    }
}



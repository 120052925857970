.hp_news.latest_news {
	margin-left: auto;
	margin-right: auto;

	.section-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;

		.ad-container {
			min-width: 300px;

			@include breakpoint(2) {
				min-width: 0;
			}
		}
  	}

	.column1,
	.column2 {
		flex: 0 0 33%;
	}

	.ad-wrapper {
  		position: relative;
    	display: block;
    	order: 3;
	}

	.ad-container {
		margin-top: -40px;
		margin-right:0;
		float: right;

		@include breakpoint(2) {
    		width: 50%;
    		float: none;
  		}
	}

	ul {
		&.articles, 
		&.column2 {
  			display: block;
  			list-style: none;

			@include breakpoint(1){
				-moz-column-count: 1;
 				-webkit-column-count: 1;
				column-count: 1;
			}

 			li {
 				border-bottom: 1px solid #f1f0ee;
 				margin: 5px 40px 10px -5px;
 				padding: 5px;

  				img {
    				@include smallImg;
  				}

				.teaser {
					display: none;
				}

  				@include breakpoint(2) {
  			 		display: inline-block;
			 		vertical-align: top;
			 		margin: 5px 40px 0px -5px;
			 		width: 90%;
					&.ad-container {
						display: block;
					}
				}

				@include breakpoint(1) {
    				width: 100%;
    				margin: 5px 5px 10px -5px;
  				}
  			}

			@include breakpoint(2){
    			width: 100%;
   				max-height:100%;
 			}
 		}

		@include breakpoint(2) {
			float: left;
		}
	}

	ul.articles {
		@include breakpoint(2) {
			columns: 2;
		}

		@include breakpoint(1) {
			columns: 1;
		}
	}
}





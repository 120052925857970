/**** This is the miscellaneous sass file. Sections which dont need its own component can be added here. ****/


// subheader section
.hp_subheader {

	.section-container {
		display: block;

		ul {
			margin-bottom: 52px;
			display: -ms-flexbox;
    		display: flex;
    		-ms-flex-direction: row;
    		flex-direction: row;
    		-ms-flex-wrap: wrap;
    		flex-wrap: wrap;
    		-ms-flex-pack: justify;
    		justify-content: space-between;

			@include breakpoint(3) {
				margin-bottom: 44px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			@include breakpoint(1) {
				display: block;
			}

    		li {
    			margin: 0 0 20px;
    			flex-basis: 30%;

				@include breakpoint(2) {
					flex-basis: 48%;
					width: auto;
				}

				@include breakpoint(1) {
					margin-bottom: 26px;
				}
    		}
		}
	}

	.subsection-title {
		margin: 10px 0 25px;
    	font-family: $font-rb;
    	text-transform: uppercase;
    	color: #2f2148;

		@include breakpoint(1) {
			margin: 10px 0 11px;
		}
	}

	.title {
	
		@include breakpoint(1) {
			font-size: 1.125rem;
			line-height: 1.25rem;
		}
	}

	@include breakpoint (1) {
		padding-bottom: 0;
	}

	.rc & { // PPE-244944

		@include breakpoint(3) {
			padding-bottom: 10px;
		}

		@include breakpoint(1) {
			margin-top: 30px;
		}

		ul {
			margin-bottom: 0;

			.featimg { // PPE-247234
				margin-bottom: 2px;
				max-height: 208px;

				@include breakpoint(2) {
					max-height: 262px;
				}

				@include breakpoint(1) {
					max-height: 380px;
				}
			}
		}
	}
}


//rc featured section
.hp_featured {

	ul {
		display: table;
	}

	li {
		padding: 10px 0;

		&:first-child {
			display: table-cell;
    		padding: 0 50px 0 0;
    		vertical-align: top;
    		width: 70%;

    		img {
    			float:left;
				margin:0 25px 0 0;
				@include largeImg;

				@include breakpoint(1) {
					margin-bottom: 10px;
					width: 100%;
					height: auto;
					min-height: 183px;
				}
    		}

			.title {
				font-size: 1.75rem;
				line-height: 2rem;

				@include breakpoint(1) {
					font-size: 1.375rem;
					line-height: 1.5rem;
    				display: block;
				}
			}

    		@include breakpoint(2){
				width:100%;
				height:auto;
				padding-bottom:20px;
			}

			@include breakpoint(1){
				padding:0;
			}

			.teaser{
				overflow:hidden;				
			}
		}
	}
}


//board blogs
.board-blog img {
    height: 80px !important;
    border-radius: 5px;
}

//Alerts
.hp_alerts {
	padding-bottom: 0;

	.section-container {
		display: none;
  		font-size: 2rem;
  		font-family: $font-rb;
  		padding-bottom: 0;

  		@include breakpoint(1) {
  			margin-top: 6px;
  			text-align: center;
  		}

  		.alert {
  			font-family: $font-rb;
			display: inline-block;
			vertical-align: middle;
			text-transform: uppercase;
			padding: 4px 6px;
			background-color: #f6f766;
			font-size: 0.9375rem;
			font-weight: bold;
			margin-right: 6px;

			&::before {
				content: "Special Coverage";
				letter-spacing: 1px;
				text-transform: uppercase;
			}

			@include breakpoint(2) {
				font-size: 0.6875rem;
				margin-right: 0;
			}

			@include breakpoint(1) {
				margin-bottom: 2px;
			}
		}

		a {
			color: $title-gray;
			font-size: 1.75rem;

			&:hover {
				color: $hover-blue;
			}

			@include breakpoint(2) {
				font-size: 1.313rem;
			}
		}

		ul {
			display: inline;

			@include breakpoint(1) {
				display: block
			}

			li {
				display:inline;
			}

			span.newstag,
			span.conftag {
				display: none;
			}
		}
	}

	.more {
		vertical-align: middle;

		@include breakpoint(2) {
			vertical-align: bottom;
		}

		a {
			font-size: 0.875rem;
			line-height: 1rem;
			color: $mscp-blue;
			margin-left: 6px;
			font-family: $font-pn;
			transition: color 0.5s;

			@include breakpoint(1) {
				margin-top: 3px;
				display: block
			}
		}
	}
}

#state-alerts a {
	@include breakpoint(1){
		display: block;
	}
}
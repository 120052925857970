.hp_columnist {
	padding-bottom: 40px;

	.section-title {
		padding: 0.875rem 0 1rem;
		font-family: $font-rb;
		font-size: 1rem;
		color: $dark-purple;

		@include breakpoint(1) {
			border-top: solid 3px #e8e6e6;
		}
	}

	.columnist-intro {
		img {
			margin-right: 16px;
			width: 90px;
			height: 90px;
			object-fit: cover;
			object-position: top;
			// use in object-fit-image polyfill for IE
			font-family: "object-fit: cover; object-position: top;";
			border-radius: 6%;
			float: left;
		}

		.title {
			padding-top: 18px;
			font-family: $font-serif;
			font-size: 1.25rem;
			line-height: 1.375rem;
			margin-bottom: 10px;
			display: block;
			transition: all 0.5s;

			&:hover {
				color: $hover-blue;
				@include hover-ease;
			}
		}

		.subtitle {
			font-family: $font-pn;
			font-size: 0.875rem;
			color: #767674;
			display: block;
			padding-left: 106px;
		}

		a {
			display: block;
			min-height: 90px;
		}
	}

	.columnist-article {
		clear: both;
		font-family: $font-rb;
		font-size: 1.125rem;
		padding-top: 4px;
	}

	.carousel-container {
		position: relative;

		@include breakpoint(3) {
			&.no-desktop-carousel:after {
				content: 'flickity';
				display: none;
			}
		}

		&::before {
			content: '';
			position: absolute;
			height: 100%;
			left: -2px;
			width: 5px;
			background: $white;
			z-index: 2;
		}

		.carousel-item {
			list-style: none;
			display: inline-block;
			vertical-align: top;
			border-left: dotted 1px #c1bfc0;
			width: 25%;
			box-sizing: border-box;
			padding: 0 25px;

			@include breakpoint(3) {
				width: 33.333333%;
			}

			@include breakpoint(2) {
				width: 50%;

				&:nth-child(odd) {
					border-left: none;
				}
			}

			@include breakpoint(1) {
				padding: 0 10px;
				width: 100%;
				border-left: none;
			}
		}

		&.no-desktop-carousel .carousel-item:first-child {
			border-left: none;
		}
	}

	.flickity-prev-next-button {
		bottom: -35px;
		top: auto;

		&:hover {
			background: none;
		}

		.arrow {
			fill: $mscp-blue;
		}

		&.previous {
			left: 40%;

			@include breakpoint(2) {
				left: 30%;
			}
		}

		&.next {
			right: 40%;

			@include breakpoint(2) {
				right: 30%;
			}
		}

		@include breakpoint(1) {
			display: none;
		}
	}
}



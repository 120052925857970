.hp_resource-center,
.hp_specialty {
	border-bottom: 5px solid $light-gray;
	padding-bottom: 6px;
	position: relative;

	#specialty-dropdown {
		display: none;

		&.resp-container {
			display: block;
		}
	}

	@include breakpoint(2) {
		border-bottom: none;
	}

	@include breakpoint(1) {
		width: 100%;
		max-width: 100%;
	}

	ul {
		list-style-type: none;
		overflow: visible;
		white-space: nowrap;
		display: flex;

		@include breakpoint(2) {
			overflow: auto;
		}

		@include breakpoint(1) {
			margin: 0;
		}

		li {
			position: relative;
			padding: 4px 14px;
			font-size: 1rem;
			margin: 0;
			line-height: 1.1em;
			transition: background-color .15s cubic-bezier(0.33, 0.66, 0.66, 1),color .15s cubic-bezier(0.33, 0.66, 0.66, 1);

			a {
				color: $title-gray;
			}

			&::before {
				position: absolute;
				width: 2px;
				background: $light-gray;
				height: 60%;
				left: 0;
				content: '';
			}

			&:hover {
				a {
					color: $white;

					@include no-hover {
						color: $title-gray;
					}
				}

				background-color: $mscp-blue;
				@include hover-ease;

				&::before,
				& + li::before {
					background: transparent;
				}

				@include no-hover {
					background-color: inherit;

					@include breakpoint(2) {
						background-color: #eeeeee;
					}
				}
			}

			@media (min-width: 1024px) and (max-width: 1279px) {
				&:nth-last-child(-n+4) {
					display: none;
				}

				&:last-child {
					display: inline-block;
				}
			}

			@media (min-width: 1024px) {
				&:first-child {
					padding-left: 0;
					&::before {
						content: '';
						background: transparent;
						width: 15px;
						height: 100%;
						top: 0;
						left: -15px;
						transition: background-color .15s cubic-bezier(0.33, 0.66, 0.66, 1),color .15s cubic-bezier(0.33, 0.66, 0.66, 1);
					}

					&:hover::before {
						background: $mscp-blue;
					}
				}
			}

			@include breakpoint(2) {
				font-size: 14px;
				background-color: #eeeeee;
				border: 1px solid $white;
				border-radius: 5%;
				padding: 0.5rem;
				margin-right: 0.5rem;

				&:hover{
					box-shadow: none;
				}

				&:first-child {
					padding: 0.5rem;
				}

				&::before {
					content: none;
				}
			}

			@include breakpoint(1) {
				&:first-child {
					margin-left: 16px;
				}
			}
		}
	}
}

.hp_resource-center {
	li:last-child {
		a {
			font-family: $font-pn-semibold;
			color: $mscp-blue;
		}

		&::before {
			content: none;
		}

		&::after {
			content: '\003E';
			color: $mscp-blue;
			padding-left: 0.2rem;
		}

		&:hover {
			background: none;
			a {
				color: $hover-blue;
			}
		}

		&:hover::after {
			color: $hover-blue;
		}

		@include breakpoint(2) {
			background: transparent;
		}
	}

	@include breakpoint(1) {
		margin-top: 16px;
	}
}

.hp_specialty {
	.specialty-toggle {
		cursor: pointer;
		position: absolute;
		top: -4px;
		right: -4px;
		border: none;
		outline: none;
		padding: 10px 0 12px 30px;
		font-family: $font-pn-semibold;
		font-size: 0.875rem;
		color: $title-gray;
		background: transparent;

		@include breakpoint(2) {
			background: #fff;
		}

		@include breakpoint(1) {
			position: relative;
			display: block;
			margin: 0 auto;
			padding: 0 30px 0 0;
		}

		&:hover {
			color: $hover-blue;
		}

		.sp-arrow {
			position: static;
			display: inline-block;
			transition: none !important;

			@include breakpoint(1) {
				& path {
					stroke: $mscp-blue;
				}
			}

			&:before, &:after {
				display: none;
			}
		}
	}

	.specialty-menu {
		box-shadow: 0 3px 5px 0 rgba(33, 57, 72, 0.29);
		padding-top: 1.25rem;
		width: 100vw;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		overflow: visible;
		z-index: $z-index_layer + 11;


		@include breakpoint(1) {
			height: 10rem;
			overflow-y: scroll;
			top: 25px;
			padding: 10px;
		}

		&.is-expanded {
			display: block;
		}

		.header-specialty-close-button {
			top: 0;
			right: calc(50% - 620px);
			cursor: pointer;

			@include breakpoint(2) {
				right: 2%;
			}

			@include breakpoint(1) {
				display: none;
			}
		}
	}

	.text-sm {
		display: none;
		font-family: $font-pn-semibold;
		font-size: 1rem;
		color: $mscp-blue;
	}

	ul li:last-child {
		@include breakpoint(3) {
			padding-right: 50px;
		}

		@include breakpoint(1) {
			padding-right: 0;
		}
	}


	@include breakpoint(1) {
		overflow: visible;
		margin-top: 6px;
		margin-bottom: 18px;
		width: 100vw;
		max-width: none;
		border-bottom: 5px solid #d8d8d8;

		ul {
			display: none;
		}

		.text-md {
			display: none;
		}

		.text-sm {
			display: inline;
		}

		.header-specialty-menu .header-specialty-menu-link {
			text-align: left;
			font-size: 20px;
		}
	}
}

.whiteout-specialty-down {
	display: none;
	background: #fff;
	opacity: 0.9;
	position: absolute;
	top: 150px;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: $z-index_layer + 10;

	@include breakpoint(1) {
		top: 250px;
	}

	&.is-visible {
		display: block;
	}
}

.is-overflow-visible {
	overflow: visible !important;
}
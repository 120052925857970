.hp_partners {

	h2 {
		margin-bottom: 40px;
		text-align: center;

		@include breakpoint(1) {
			margin-bottom: 30px;
		}
	}

	ul {
		display: flex;
		flex-direction: row; 
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		@include breakpoint(1) {
			display: block;
		}
	}

	li {
		margin: 0 40px 40px 40px;
		text-align: center;

		@include breakpoint(1) {
			margin: 0 0 30px 0;
		}

		img {
			height: 70px;
		}
	}

	span {
		padding-top: 22px;
		font-family: $font-rb;
		font-size: 0.875rem;
		line-height: 1rem;
		color: $byline-gray;
		display: block;

		&:hover {
			color: $hover-blue;
			@include hover-ease;
		}
	}
}
.title {
	font-family: $font-serif;
	font-size: 1.25rem;
	color: $title-gray;
	text-align: left; //For IE
	text-align: start;
	line-height: 1.5rem;
}

img{
	// use in object-fit-image polyfill for IE
    font-family: "object-fit: cover; object-position: center;";
}

.hp_news,
.hp_bucket2col,
.hp_bucket3col {
	a:hover {
		text-decoration: none;

		.title {
			color: $mscp-blue;
			@include hover-ease;
		}
	}
}

.teaser {
	padding: 8px 0 0;
	font-family: $font-pn-light;
	font-size: 1.125rem;
	line-height: 1.375rem;
	text-align: left; //For IE
	text-align: start;

	@include breakpoint(2){
		font-size: 1rem;
	}
	@include breakpoint(1) {
		display: none;
	}
}

.byline {
	font-size: 0.875rem;
	font-family: $font-pn;
	line-height: 1.25;
	color: $byline-gray;
	margin: 5px 0;
	text-align: left; //For IE
	text-align: start;

	span {

		&:after {
			content: "|";
			padding-left: 5px;
			font-style: normal;
		}

		//&:last-child:after { 
    		//content: "";
    		//padding: 0;
		//}


		.viafoura-commentcount:after {
			content: "";
			padding: 0;
		}

		.livefyre-commentcount:after {
			content: "";
			padding: 0;
		}

		.viafoura-commentcount:after {
    		content: "";
    		padding: 0;
		}

		&.lastVisible:after{
			content: "";
    		padding: 0;
		}
	}

	.publication {
		font-style: italic;
	}

	.location{
		background: url('/pi/global/icons/icon-location-gray.svg') no-repeat left center;
		background-size:13px auto;
		padding-left:16px;
		border-left:0;
	}

	.trend{
		background: url('/pi/global/icons/icon-trending-red.svg') no-repeat 7px center;
		background-size:17px auto;
		display: none; //for initial release
	}

	.video{
		background: url('/pi/global/icons/icon-video-play-btn-gray.svg') no-repeat 2px center;
		background-size:15px auto;
		padding-left:20px;
	}

	.audio{
		background: url('/pi/global/icons/icon-audio-gray.svg') no-repeat 2px center;
		background-size:15px auto;
		padding-left:20px;
	}

	.slide{
		background: url('/pi/global/icons/icon-slideshow-gray.svg') no-repeat 2px center;
		background-size:15px auto;
		padding-left:20px;
	}
	
	span.date-range{
		background: url('/pi/global/icons/icon-calendar-gray.svg') no-repeat left center;
		background-size:15px auto;
		padding-left:18px;
	}

	@include breakpoint(1) {
		font-size: 0.875rem;
	}
}

.ifi{
	background-color:$light-gray;
}

.tag {
	font-size: 0.875rem;
	font-family: $font-rb;
	text-transform: uppercase;
}

.newstag{
	color:$red;
}

.conftag{
	color:$green;
}

.callouttag {
	color:$byline-gray;
	text-align: left;
	display:inline;

	a {
		color: $byline-gray;
	}
}


li:not(.nativecallouts-item) .callouttag {
    font-style: italic;
}

.section-title {
	color: $title-gray;
	font-family: $font-pn-light;
	font-size: 1.5rem;
	line-height: 1.625rem;
	max-width: 100%;
	padding-top: 20px;
	text-transform: uppercase;

	@include breakpoint(3) {
		width: 100%;
	}

	@include breakpoint(1) {
		font-size: 1.125rem;
		width: 100%;
	}

	&:empty {
		display: none;
	}
}

.ie-object-fit {
	position: relative;
	background-size: cover;
	background-position: center center;
	img {
		opacity: 0;
	}
}

//temp hack for slug alignment
main:not(.coe) .hp_bucket2col,main:not(.coe) .hp_featured{
	li:first-child { 
		.tag:first-child{
			margin-left: 385px;
			@include breakpoint(1) {
				margin-left: 0;
			}
	}	}
}

// temp remove conf date for OUS
.mscp-global .date-range {
	display: none;
}
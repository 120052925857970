@mixin breakpoint($breakpoint) {
	@if $breakpoint == 1 {
     	@media (max-width:767px)  { @content; }
  	}
  	@else if $breakpoint == 2 {
     	@media (max-width: 1023px) { @content; }
  	}
  	@else if $breakpoint == 3 {
     	@media (max-width: 1279px) { @content; }
  	}
  	@else if $breakpoint == 4 {
     	@media (min-width: 1280px) { @content; }
  	}
}


@mixin linear-bg($direction,$start,$end) {
	background: -webkit-linear-gradient(to $direction,$start,$end);
    background: -o-linear-gradient(to $direction,$start,$end);
    background: -moz-linear-gradient(to $direction,$start,$end);
    background: linear-gradient(to $direction,$start,$end);
}

@mixin exLargeImg {
	width: 450px;
	height: 253px;
	object-fit: cover;
	object-position: center;
	// use in object-fit-image polyfill for IE
	font-family: "object-fit: cover; object-position: center;";
}

@mixin largeImg {
    width: 360px;
    height: 203px;
    object-fit: cover;
    object-position: center;
	font-family: "object-fit: cover; object-position: center;";
}

@mixin mediumImg {
    width: 280px;
    height: 158px;
    object-fit: cover;
    object-position: center;
	font-family: "object-fit: cover; object-position: center;";
}

@mixin smallImg {
    float: right;
    margin-left: 10px;
    width: 80px;
    height: 45px;
    object-fit: cover;
    object-position: center;
	font-family: "object-fit: cover; object-position: center;";
}

@mixin consultImg {
    float:right;
    margin-left:10px;
    width:83px;
    height:83px;
    object-fit: cover;
    object-position: center;
}

@mixin hover-ease {
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	transition: all 300ms ease;
}

// no hover for touch devices
@mixin no-hover {
	@media (hover:none), (hover:on-demand) {
    	@content;
	}
}
/*! Flickity v2.0.9
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  cursor: pointer;
  /* vertically center */
  transform: translateY(-50%);
  color: $mscp-blue;
  outline: none;
  background: transparent;

  @include breakpoint(1) {
    display: none;
  }

  &:disabled {
    display: none;

    & + .flickity-page-dots {
      display: none;
    }
  }
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 10px;
  border-radius: 0 3px 3px 0;

}
.flickity-prev-next-button.next {
  right: 10px;
  border-radius: 3px 0 0 3px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */

.flickity-page-dots {
  width: 100%;
  padding: 30px 0 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;

	@include breakpoint(1) {
		padding: 20px 0 0;
	}
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  background-color: #70858f;
  height: 10px;
  width: 10px;
  margin: 3px 5px;
  padding: 0;

  &:only-child {
    display: none;
  }

  &:hover {
    background-color: #a8c6f0;
  }

  &.is-selected {
    background-color: $light-blue;
    cursor: default;
  }
}
$colors: #D54C38 #DE6C42 #E4854A #EB9E52 #EDB855;

.hp_trending {
    width: 100%;
    max-width: 100%;
    padding-bottom: 25px;
    @include linear-bg(right,#DBE5F0, #EBF3F5);

    .section-title {
        background: url('/pi/global/icons/icon-trending-red.svg') no-repeat 10px 25px;
        background-size: 33px auto;
        padding-left: 50px;

        .section-subtitle{
            font-family: $font-rb;
            font-size: 1.25rem;
            color: $medium-gray;
        }

        span {
    		font-size: 20px;
    		font-family: "Roboto Condensed",sans-serif;
    		color: #666;

    		@include breakpoint(1) {
    	    	font-size: 0.9375rem;
    	    	display: block;
    		}
		}
    }

	.section-container {
		padding: 10px 0 0 0;
		@include breakpoint(1) {
			&:after {
				content: 'flickity';
				display: none;
			}

			.flickity-prev-next-button {
				display: none;
			}
			.flickity-page-dots {
				display: block !important;
			}
		}
	}

	ul {
		list-style: none;
		display: flex;
		margin: 0 auto;
    	max-width: 96%;
    	width: 1240px;

		@include breakpoint(3) {
			overflow-x: hidden;
		}

		@include breakpoint(2) {
			overflow-x: scroll;
		}

		@include breakpoint(1) {
			max-width: 100%;
			overflow-x: hidden;
			display: block;
		}

		li {
			background-color: #fafafa;
			text-align: center;
			flex: 1;
			padding: 20px;
			margin: 10px;
			width: 12rem;
			box-shadow: 0 0 10px 0 rgba(98, 120, 141, 0.2);
			transition: transform 0.3s;
			position: relative;
			top: 0;

			@include breakpoint(3) {
				padding:1 0px;
				margin: 5px;
				width: 10rem;
			}

			@include breakpoint(2) {
				width: auto;
				flex: 0 0 12rem;
			}

			.index {
				font-family: $font-pn-semibold;
				font-size: 2.25rem;
			}

			.title {
				font-size: 1.25rem;
				text-align: center;

				a {
					color: $title-gray;

					&:hover {
						color: $mscp-blue;
						text-decoration: none;
						@include hover-ease;
					}
				}
			}

			.byline {
				color: #979797;
				font-size: 0.875rem;
			}

			@for $i from 1 through length($colors) {
				&:nth-child(#{$i}) .index{
					color: nth($colors, $i);
				}
			}

			@include breakpoint(1) {
				min-height: 125px;
				width: 70%;
				min-width: 10rem;

				 &.dot {
					 min-height: 0;
					 min-width: 0;
				}
			}

			&:not(.dot):hover {
				transform: translateY(-5px);
				box-shadow: 0 0 10px 0 rgba(98, 120, 141, 0.5);
			}
		}
	}

	.flickity-slider {

		li:hover {
			transform: translateY(-5px);
		}

		@include breakpoint(1) {
			width: 100%;
		}
	}
}

.page-body {
	color: $light-black;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

h1 {
	font-family: $font-pn-light;
	font-size: 2.5rem;
}

ul, li {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	&,
	&:link {
		color: $mscp-blue;
		text-decoration: none;
	}
	&:visited {
		color: $link-purple;
		text-decoration: none;
	}
	&:hover {
		color: $hover-blue;
		@include hover-ease;
		text-decoration: none;
	}
	&:active {
		color: $mscp-blue;
		text-decoration: none;
	}
}


p {
	margin: 0;
	padding: 0 0 15px 0;
}

p:empty {
    display: none;
}

img {
	border: 0;
	max-width: 100%;
}

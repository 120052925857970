.resp-container {
	&.rc {
		section {
			&.hp_poll {
				margin-bottom: 20px;
				padding-top: 20px;
				box-sizing: border-box;
				position: relative;
				width: 100vw;
				max-width: 100vw;
				overflow: hidden;

				@include breakpoint(1) {
					margin-bottom: 10px;
				}

				h2 {
					padding-top: 0;
				}

				.section-title,
				.qna-render {
					margin: 0 auto;
					box-sizing: border-box;
					width: 1260px;
					max-width: 96%;

					@include breakpoint(3) {
						margin: 0 15px;
						padding-left: 5px !important;
						width: auto;
						max-width: 96%;
					}

					@include breakpoint(1) {
						margin: 0 20px;
						max-width: 90%;
					}
				}

				.section-title {
					padding: 20px 0;

					@include breakpoint(1) {
						padding: 0 0 15px;
					}
				}

				.qna-render {
					padding: 0 0 20px;

					@include breakpoint(1) {
						padding: 0 0 10px;
					}

					.question-content p {
						font-family: $font-pn-semibold;
						font-size: 1.125rem;
						line-height: 1.375rem;
					}
				}

				.qna-question input[type="checkbox"] + div.answer-bar + div.answer-content ans, 
				.qna-question input[type="radio"] + div.answer-bar + div.answer-content ans {
					margin-top: 11px;
				}
			}
		}
	}
  &.coe{
    section{
      &.hp_poll{
        box-sizing: border-box;
        position: relative;
        width: 100vw;
        max-width: none;
        overflow: hidden;
        margin-left: calc((1225px - 100vw) / 2);
        padding-top: 20px;
        .section-title,
        .qna-render{
          box-sizing: border-box;
          max-width: 1220px;
          margin: 0 auto 0 auto;
          padding-right: 348px;
          padding-left: 0;
        }
        @include breakpoint(3) {
          margin-left: calc((988px - 100vw) / 2);
          .section-title,
          .qna-render{
            width: 988px;
          }
        }
        @include breakpoint(2) {
          margin-left: calc((96vw - 100vw) / 2);
          .section-title,
          .qna-render{
            width: 96%;
          }
        }
        @include breakpoint(1) {
          margin-left: calc((90vw - 100vw) / 2);
          .section-title,
          .qna-render{
            width: 96%;
            padding-right: 0;
          }
        }


        h2{
          padding-top: 0;
        }

      }
    }

  } /* &.coe */
}
  section.hp_poll {
    background: #eff3f6;
    position:relative;
    max-width: 100vw;
    .section-title {
      padding: 24px 0 12px;
      max-width: 1220px;

      @include breakpoint(1) {
        padding-bottom: 0;
        max-width: 90%;
      }
    }

    fieldset,
    .qna-qnaresponse {
      max-width: 770px;
    }

    .qna-render {
      position: relative;
      padding: 0 10px;
      max-width: 100%;
      @include breakpoint(1) {
        max-width: 90%;
      }
      .question-content {
        p {
          font-size: 22px;
          padding-bottom: 28px;
          color: #333132;

          @include breakpoint(1) {
            font-size: 16px;
            padding-bottom: 15px;
          }
        }
      }

      .answer-content {
        p {
          color: #222222;
        }
      }

      .qna-question:not(.answered) label:hover {
        background: #d8e8e7;
      }

      .qna-poll-chart {
        font-size: 20px;
        padding-bottom: 12px;
        margin-top: -38px;
        @include breakpoint(2) {
          font-size: 22px;
          margin-top: -18px;

        }
        @include breakpoint(1) {
          font-size: 16px;
          margin-top: 0;

        }
      }

      h2 {
        display: none;
      }

      .errorMsg {
        position: absolute;
        bottom: 66px;
        color: #d0021b;
      }

      .qna-button.qna-submit-invalid {
        background-color: #d1d1d1;
      }
    }
  }

.hp_conf-coverage {

	.section-container {
		display: block;
	}

	ul {
		overflow: hidden;
      	width: 100%;

      	@include breakpoint(1) {
			display: flex;
			flex-direction: column;
			padding-bottom: 40px;
		}

		li {
			padding: 10px 0;

			img {
				float: right;
			}

			&:not(:first-child) {
				float: right;
				width: 31%;
				margin: 25px 25px 0 0;

				img {
					@include smallImg;
				}

				@include breakpoint(2) {
					float: left;
					width: calc(50% - 25px);
					border-bottom: 1px solid $lighter-gray;
				}

				@include breakpoint(1) {
					width: 100%;
					float: none;
					border: none;
				}
			}

			&:nth-child(4) {
				clear: both;
			}

			&:nth-child(2) {
				margin-top:0;
			}

			&:nth-child(n+5) {
				display: inline-block;
				float: left;
				margin-right: 25px;
				vertical-align: top;
				width: calc(33.33% - 25px);

				@include breakpoint(2) {
					padding: 0 0 10px 0;
					width: calc(50% - 25px);
				}

				@include breakpoint(1) {
					width: 100%;
					float: none;
				}
			}

			&:hover {
				text-decoration: none;

				.title {
					color: $hover-blue;
					@include hover-ease;
				}
			}

			&.blog {
				margin-top: 0;
				padding: 0;
				background: #eaf1f7;
				color: $title-gray;
				display: none;

				.blog-header {
					padding: 17px 11px 9px 11px;
					text-align: center;
				}

				.blog-conference {
					padding: 0 0 3px;
					text-transform: uppercase;
					font-family: $font-rb;
					font-size: 1rem;
					display: block;
					@include breakpoint(2) {
						font-size: 0.9rem;
					}
				}

				.blog-name {
					text-align: center;
					font-family: $font-pn-semibold;
					font-size: 1.375rem;
					@include breakpoint(2) {
						font-size: 1.1rem;
					}
				}

				.blog-content {
					padding: 0 11px;
				}

				.blog-link {
					padding: 6px 0 19px 0;
					text-align: center;

					a {
						padding: 7px 24px 7px 18px;
						background: url('/pi/global/icons/icon-arrow-rightstatus-white.png') no-repeat right 10px center;
						-webkit-border-radius: 3px;
						-moz-border-radius: 3px;
						border-radius: 3px;
						background-color: $mscp-blue;
						background-size: 10px;
						font-family: $font-pn-semibold;
						color: $white;
						display: inline-block;

						&:hover {
							background-color: $hover-blue;
							text-decoration: none;
							@include hover-ease;
						}
					}
				}

				section {
					padding: 0;
					max-width: auto;
					width: auto;
				}

			}

			&:nth-child(2).hasBlog {
				display: block;

				@include breakpoint(2) {
					float: left;
					margin: 15px 25px 0 0;
					padding-bottom: 0;
					width: calc(50% - 25px);
					border: none;
				}
 
				@include breakpoint(1) {
					text-align: left;
					width: 100%;
					float: left;
				}

				img { //for testing
					@include breakpoint(2) {
						float:left;
					}
				}
			}

			&:nth-child(2).hasBlog ~ li {
				float: left;
				width: calc(33.33% - 25px);
				display: inline-block;

				@include breakpoint(2) {
					margin: 15px 25px 0 0;
					width: calc(50% - 25px);
				}

				@include breakpoint(1) {
					width: 100%;
					float: none;
				}
			}
		
			&:nth-child(2).hasBlog~li:nth-of-type(3n+3) {
				clear: both;

				@include breakpoint(2) {
					clear: none;
				}
			}

			&:nth-child(2).hasBlog + li +li {
				 clear: none;
			}
		}

		&:nth-of-type(1) {

			li:first-child { 
				width: 65%;
				padding: 0;
				float: left;

				.title {
					font-size: 1.75rem;
					line-height: 2rem;
					margin-top: -4px;
					margin-left: 385px;

					@include breakpoint(2) {
						margin-left: 305px;
					}

					@include breakpoint(1) {
						margin-left: 0;
						font-size: 1.3rem;
						line-height: 1.6rem;
					}
				}

				.teaser {
					@include breakpoint(2) {
						overflow:hidden;
					}
				}

				img {
					float: left;
					margin: 0 25px 0 0;
					position: relative;
					@include largeImg;

					@include breakpoint(2) {
						@include mediumImg;
					}

					@include breakpoint(1) {
						height: auto;
						min-height: 183px;
						width: 100%;
					}
				}

				.imgVideo {
					@include breakpoint(2) {
						left: -295px;
						top: 110px;
					}
				}

				@include breakpoint(2) {
					width: 100%;
					padding-bottom: 0;
					margin-bottom: 25px;
				}

				@include breakpoint(1) {
					margin-bottom: 0;
					width: 100%;
					float: none;
				}
			}

			@include breakpoint(1) {
				padding-bottom: 15px;
			}
		}

		&:nth-of-type(2) {

			li {
				margin: 25px 25px 0 0;
				width: calc(33.33% - 25px);
				vertical-align: top;
				display: inline-block;
				float: left;

				img {
					@include smallImg;
				}

				@include breakpoint(2) {
					padding: 0 0 10px 0;
					width: calc(50% - 25px);
				}

				@include breakpoint(1) {
					width: 100%;
					border: none;
					float: none;
				}
			}
		}
	}
}

// hide feature section when conference coverage is present
.hp_conf-coverage + .hp_bucket2col {
	display: none;
}
.hp_mtv {
  width: 100%;
  max-width: none;
  background: linear-gradient(135deg, #0a1729 0%,#0f2d57 100%);
  padding-bottom: 30px;
  margin-bottom: 30px;
  position: relative;

  .section-title{
    color: transparent;
    text-align: center;
    padding: 30px 0;
    background: url('/pi/global/logos/mtv-logo-white.png') no-repeat center;
    background-size: 194px;

    @include breakpoint(1) {
      padding: 12px 0;
      background-size: 104px auto;
    }
  }

  .carousel-container {
    width: 1240px;
    max-width: 96%;
    margin: auto;

    &.unwrapAround {
      width: 980px;
    }

    .carousel-item {
      transition: opacity 0.5s, transform 0.5s;
      position: relative;
      width: 40%;
      height: 420px;
      opacity: 0.3;
      transform: scale(0.8);
      transform-style: preserve-3d;
      backface-visibility: hidden;

      @include breakpoint(2) {
        height: 320px;
      }

      @include breakpoint(1) {
        width: 98%;
        height: 257px;
      }

      a {
        display: block;
        width: 730px;
        height: 411px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-style: preserve-3d;
        backface-visibility: hidden;
        overflow: hidden;
        pointer-events: none;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.69));
          z-index: 4;

          @include breakpoint(1) {
            background-image: none;
          }
        }

        @include breakpoint(2) {
          width: 544px;
          height: 306px;
        }

        @include breakpoint(1) {
          width: 100%;
          height: auto;
          position: static;
          transform: none;
        }

        img {
          width: 100%;
          object-fit: cover;
          object-position: center;
          transition: transform 0.5s;
          filter: blur(6px);

          @include breakpoint(1) {
            width: 100%;
            height: 168px;
            position: static;
            transform: none;
          }
        }

        .text {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transform-style: preserve-3d;
          backface-visibility: hidden;
          width: 500px;
          padding: 0 200px 30px 34px;
          z-index: 6;

          @include breakpoint(2) {
            width: 517px;
            padding: 0 0 10px 28px;
          }

          @include breakpoint(1) {
            position: relative;
            width: 100%;
            left: auto;
            bottom: auto;
            padding: 1rem 0 0;
            transform: none;
          }
        }


      }

      &.is-selected {
        opacity: 1;
        z-index: 10;
        transform: none;

        a {
          pointer-events: auto;
        }

        img {
          filter: none;
        }

        .title,
        .teaser {
          display: block;
        }

        &:hover {
          .title::before {
            background-image: url("/pi/global/icons/icon-mtv-video-hover.svg");
          }

          img {
            transform: scale(1.1);
          }

        }

        @include breakpoint(2) {
          .teaser {
            display: none;
          }
        }
      }
    }
  }

  .title {
    color: #fff;
    margin-bottom: 0.75rem;
    display: none;
    font-size: 26px;

    @include breakpoint(2) {
      font-size: 22px;
    }

	&::before {
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      top: -53px;
      background-size: contain;
      background-image: url("/pi/global/icons/icon-mtv-video-default.svg");
      transform-style: preserve-3d;
      backface-visibility: hidden;


      @include breakpoint(1) {
        top: -110px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .teaser {
    color: #d4d4d4;
    font-size: 1rem;
    font-family: $font-pn-light;
    display: none;
  }

  .flickity-prev-next-button {
    width: 60px;
    height: 60px;
    background-color: $light-blue;
    color: $white;
    z-index: 2;

    .arrow {
      fill: $white;
    }

    &.previous {
      left: 0;
    }

    &.next {
      right: 0;
    }

    &:hover {
      background: #a8c6f0;
    }
  }
}
section{
	padding-bottom: 20px;
}

.section-container {
	display: flex;
	padding: 20px 0 0 0;

	@include breakpoint(1){
		padding: 10px 0 0;
		display: block;
	}

	ul{
		list-style:none;
	}
}

main:not(.coe) .hp_bucket2col,
.hp_2colCallout {

	ul{

		display:table;
		width:100%;

		@include breakpoint(3){
			text-align: justify;

			&:after {
    			content: " ";
    			width: 100%;
			}
		}

		@include breakpoint(2){
			text-align: justify;
			display:inline-block;
			&:after {
				display:inline-block;
			}
		}
	}

	li{
		padding: 10px 0;

		img{
			float:right;
		}
	
		&:first-child { 
			width: 65%;
			display: table-cell;
			padding: 0 50px 0 0;
			vertical-align: top;

			.title {
				font-size: 1.75rem;
				line-height: 2rem;

				@include breakpoint(1) {
					font-size: 1.375rem;
					line-height: 1.5rem;
    				// display: inline-block;
				}
			}

			img{
				float:left;
				margin:0 25px 0 0;
				@include largeImg;

				@include breakpoint(1) {
					margin-bottom: 10px;
					width: 100%;
					height: auto;
					// min-height: 183px;
				}
			}

			@include breakpoint(2){
				width:100%;
				height:auto;
				padding-bottom:20px;
			}

			@include breakpoint(1){
				padding:0;

				.callouttag {
					display: inline-block;
				}
			}

			.teaser{
				overflow:hidden;				
			}
		}

		&:not(:first-child){
			@include breakpoint(2){
				width: calc(50% - 30px);
				vertical-align:top;
				display:inline-block;
			}
			@include breakpoint(1){
				width: 100%;
			}

			img{
				@include smallImg;
			}
			.teaser{
				display:none;
			}
		}

		&:not(:first-child):not(:last-child) {
			overflow: hidden;
  			border-bottom: 1px solid $lighter-gray;

  			@include breakpoint(3){
  				border:none;
  				overflow:inherit;
  				margin-right:25px;
  			}
  			
  			@include breakpoint(2){
  				margin-right:0;
  				border-bottom: 1px solid $lighter-gray;
  			}

			@include breakpoint(1){
				border:none;
			}
		}

		&:nth-child(2):nth-last-child(2) {
			@include breakpoint(3) {
				margin-right: 0;
			}
		}

		&:first-child:nth-last-child(4) ~ li{
			@include breakpoint(3){
				display:inline-block;
				vertical-align:top;
				width: calc(33.33% - 25px);
			}
			@include breakpoint(2){
				width: calc(50% - 20px);
			}
			@include breakpoint(1){
				width:100%;
			}
		}

		&:first-child:nth-last-child(4){
			@include breakpoint(3){
				width:100%;
				height:auto;
				margin-bottom: 20px;		
				display: inline-block;
			}
			@include breakpoint(2){
				width: calc(50% - 20px);
				float:none;
				display: inline-block;
				margin:0;
				padding:10px 0;
				border-bottom: 1px solid $lighter-gray;
			}
			@include breakpoint(1){
				width:100%;
				border:none;
			}
			img{
				@include breakpoint(2){
					margin:0;
					@include smallImg;
				}
				@include breakpoint(1){ //temp hack
					margin-bottom: 15px;
					width:100%;
					height:auto;
				}
			}
			.title{
				@include breakpoint(2){
					font-size: 1.313rem;
				}
				@include breakpoint(1){
					font-size: 1.5rem;
				}
			}
			.teaser{
				@include breakpoint(2){
					display:none;
				}
			}		
		}

		&:nth-last-of-type(2):not(.nativecallouts-item) {
			@include breakpoint(2) {
				border: none !important;
			}
		}
	}
}

.hp_bucket3col{

	.section-container{
		display:block;
	}
	ul{
		display: flex;
		flex-direction: row; 
		flex-wrap: wrap;
		justify-content:space-between;
	}
	li{
		flex-basis: 30%;
		margin:0 0 20px 0;

		img{
			@include smallImg;
		}
		
		&:not(:nth-last-child(-n+3)){
			border-bottom:1px solid $lighter-gray;
			overflow:hidden;

			@include breakpoint(1){
				border-bottom:none;
			}
		}

		@include breakpoint(3){
			flex-basis:auto;
			width:calc(33.33% - 25px);
			margin-right:25px;
		}

		@include breakpoint(2){
			flex-basis:48%;
			width:auto;
			margin-right:0;
			border-bottom: 1px solid $lighter-gray;
			
			&:nth-last-child(-n+2) {
				border: none;
			}
		}

		@include breakpoint(1){
			flex-basis:100%;
		}
	}
}

.eleVideo {
    position: relative;
}

.eleVideo + a {
	@include breakpoint(1){
    	display: inline-block;
    	margin-top: -25px;
    }
}

.imgVideo{
	background: rgba(0,0,0,0.6) url("/pi/global/icons/icon-mtv-video-default.svg") no-repeat scroll 10px center;
	background-size:20px auto;
	color:#fff;
	border-radius:3px;
	padding: 9px 13px 7px 37px;
	left: -370px;
	top:150px;
	position:absolute;

	&::before {
    	content: "Video";
	}

	/* @include breakpoint(3){
		left: -295px;
    	top: 110px;
	}*/

	@include breakpoint(1){
		display:inline-block;
		position:relative;
		left: 10px;
    	top: -55px;
	}

}

section ul li:not(:first-child),
.hp_news {
	.imgVideo {
		display: none;
	}
}


.more {
    font-family: $font-pn-semibold;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: capitalize;
}

.more a{
	font-size: 0.875rem;
	color: $mscp-blue;
	margin-left: 6px;
    font-family: $font-pn-semibold;
	transition: color 0.5s;

	&:hover {
      color: $hover-blue;

      &::after {
		  border: solid $hover-blue;
		  border-width:0 2px 2px 0;
      }

	}

	&::after{
		border: solid $mscp-blue;
		border-width:0 2px 2px 0;
		content: " ";
		display:inline-block;
		height:6px;
		width:6px;
		margin-left:0.2rem;
		transform: rotate(-45deg);
	}

	@include breakpoint(1){
    	margin-left:0;
    }
}



.section-container + .more {
    display:none;
    @include breakpoint(1){
    	display:block;
    }
}	

.section-title .more {
    @include breakpoint(1){
    	display:none;
    }
}


.hp_bucket3col + .hp_bucket2col,
.hp_bucket2col + .hp_bucket3col,
.hp_bucket2col + .hp_bucket2col,
.hp_committee + .hp_bucket2col,
.hp_committee + .hp_bucket3col,
.hp_bucket3col + .hp_leftAd,
.hp_bucket2col + .hp_leftAd,
.hp_leftAd + .hp_bucket2col,
.hp_leftAd + .hp_bucket3col,
.hp_featured + .hp_bucket3col,
.hp_committee + .hp_news,
.hp_bucket3col + .hp_news,
.hp_bucket2col + .hp_news,
.hp_featured + .hp_news {
	border-top: 2px solid $header-gray;
}

// anchor tags
.rc-anchor {
	font-size: 0;
	line-height: 0;
}

//hide teaser for bucket3col coe
.coe{
	.hp_bucket3col{
		li:first-child{
			.teaser{
				display:none;
			}
		}
	}
}

.cc {
	.section-container + .more {
		@include breakpoint(1){
			display:none;
		}
	}
}

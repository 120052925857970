// special coverage section | PPE-244627

.hp_2colCallout {
	margin: 31px auto 16px;
	border: 1px solid $header-gray;
	border-radius: 6px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.21);

	@include breakpoint(1) {
		margin: 15px auto;
	}

	.section-title {
		padding: 22px 0 13px 23px;
		font-family: $font-rb;
		font-size: 1.063rem;
		line-height: 1.25rem;
		color: #82091c;
		width: auto;
		display: inline-block;

		@include breakpoint(3) {
			padding-left: 4px;
		}

		@include breakpoint(2) {
			padding: 18px 0 0 6px;
		}

		@include breakpoint(1) {
			padding: 12px 0;
			text-align: center;
			display: block;
		}

		a {
			padding: 10px 36px 10px 13px;
			border-radius: 5px;
			color: #82091c;
			background: #fff url("/pi/global/icons/icon-circle-arrow-right-red.svg") no-repeat;
			background-size: 19px auto;
			background-position: bottom 11px right 12px;

			&:hover {
				background: #e6e6e6 url("/pi/global/icons/icon-circle-arrow-right-red.svg") no-repeat;
				background-size: 19px auto;
				background-position: bottom 11px right 12px;
			}
		}

		b,
		strong {
			font-family: $font-rb;
			font-weight: 700;
		}

		.more {
    		display: none;
    	}
	}

	.section-intro {
		padding: 23px 0 0;
		vertical-align: top;
		display: inline-block;

		@include breakpoint(2) {
			padding: 18px 0 0;
		}

		@include breakpoint(1) {
			display: none;
		}

		ul {
			width: auto;

			li {
				margin: 0 0 0 13px !important;
				padding: 0 0 0 16px;
				border-left: 1px solid #82091c !important;
				border-bottom: none !important;
				font-family: $font-rb;
				font-size: 1rem;
				text-transform: uppercase;
				vertical-align: baseline !important;
				display: inline;
				width: auto;

				a {
					color: #a3a3a3;

					&:hover {
						color: $title-gray;
					}
				}

				&:first-child {
					padding: 0;
					border: none !important;

					@include breakpoint(3) {
						margin-left: 7px !important;
					}
				}

				@include breakpoint(3) {
					&:last-child {
						display: none;
					}
				}

				@include breakpoint(2) {
					&:nth-child(n+5) {
						display: none;
					}
				}
			}
		}
	}

	.section-container {
		padding: 20px 51px 0 27px;

		@include breakpoint(2) {
			padding: 20px 18px 0;
		}

		@include breakpoint(1) {
			padding: 0 11px;
		}

		a:hover {
			.title {
				color: $hover-blue;
			}
		}

		+ .more {
			padding: 0 51px 0 0;
			font-size: 0.875rem;
			font-weight: 400;
			text-align: right;
			display: block;

			@include breakpoint(1) {
				padding: 13px 0 0;
				text-align: center;
			}
		}
	}	
}
